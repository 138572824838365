<template>
  <div class="box">
    <div class="banner">
      <div class="item">
        <div class="t-wrap">
          <img src="~@/assets/images/loan/bank.png" alt="" />
          <div class="info">
            <div class="col">{{ obj.fpProductName }}</div>
            <div class="col">{{ obj.fpOrganizationName }}</div>
          </div>
        </div>
        <div class="b-wrap">
          <div class="detail">
            <div class="text">贷款额度</div>
            <div class="num">{{ obj.fpLoanAmount }}万元</div>
          </div>
          <div class="detail">
            <div class="text">担保方式</div>
            <div class="num">{{ obj.fpAssureMeans }}</div>
          </div>
          <div class="detail">
            <div class="text">贷款期限</div>
            <div class="num">{{ obj.fpLoanDeadline }}月</div>
          </div>
          <div class="detail">
            <div class="text">参考利率</div>
            <div class="num">
              {{ obj.fpReferenceRateStart }}%~{{ obj.fpReferenceRateEnd }}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="evaluation">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="satisfaction"
            type="number"
            name="satisfaction"
            label="满意度评价"
            placeholder="满分为10分，最低为0分"
            :rules="[
              { required: true, message: '请填写满意度评价' },
              {
                validator: satisfactionValidator,
                message: '满分为10分，最低为0分',
              },
            ]"
          />
          <van-field
            v-model="bank"
            type="number"
            placeholder="满分为10分，最低为0分"
            name="bank"
            label="银行评价"
            :rules="[
              { required: true, message: '请填写银行评价' },
              { validator: bankValidator, message: '满分为10分，最低为0分' },
            ]"
          />
        </van-cell-group>
        <div class="btn">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
          <van-button round block> 取消 </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { financialBankProductDetail } from "@/api/loan.js";
import { scoreProductAndBank } from "@/api/evaluation.js";
export default {
  name: "LoanDetail",
  data() {
    return {
      obj: "",
      satisfaction: "",
      bank: "",
      faUuid: this.$route.query.faUuid,
    };
  },
  mounted() {
    this.getFinancialBankProductDetail();
  },
  methods: {
    getFinancialBankProductDetail() {
      financialBankProductDetail({
        fpUuid: this.$route.query.uuid,
      }).then((res) => {
        if (res.success) {
          this.obj = res.data || {};
        } else {
          this.$toast.fail(res.actionErrors[0]);
        }
      });
    },
    onSubmit(values) {
      scoreProductAndBank({
        faUuid: this.faUuid,
        faProductScore: values.satisfaction,
        faBankScore: values.bank,
      }).then((res) => {
        if (res.success) {
          this.$toast.success(res.data);
        } else {
          this.$toast.fail(res.actionMessages[0]);
        }
      });
    },
    satisfactionValidator(val) {
      if (val > 10 || val < 0) {
        return false;
      } else {
        return true;
      }
    },
    bankValidator(val) {
      if (val > 10 || val < 0) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
