var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"t-wrap"},[_c('img',{attrs:{"src":require("@/assets/images/loan/bank.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.obj.fpProductName))]),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.obj.fpOrganizationName))])])]),_c('div',{staticClass:"b-wrap"},[_c('div',{staticClass:"detail"},[_c('div',{staticClass:"text"},[_vm._v("贷款额度")]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.obj.fpLoanAmount)+"万元")])]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"text"},[_vm._v("担保方式")]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.obj.fpAssureMeans))])]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"text"},[_vm._v("贷款期限")]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.obj.fpLoanDeadline)+"月")])]),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"text"},[_vm._v("参考利率")]),_c('div',{staticClass:"num"},[_vm._v(" "+_vm._s(_vm.obj.fpReferenceRateStart)+"%~"+_vm._s(_vm.obj.fpReferenceRateEnd)+"% ")])])])])]),_c('div',{staticClass:"evaluation"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-field',{attrs:{"type":"number","name":"satisfaction","label":"满意度评价","placeholder":"满分为10分，最低为0分","rules":[
            { required: true, message: '请填写满意度评价' },
            {
              validator: _vm.satisfactionValidator,
              message: '满分为10分，最低为0分',
            } ]},model:{value:(_vm.satisfaction),callback:function ($$v) {_vm.satisfaction=$$v},expression:"satisfaction"}}),_c('van-field',{attrs:{"type":"number","placeholder":"满分为10分，最低为0分","name":"bank","label":"银行评价","rules":[
            { required: true, message: '请填写银行评价' },
            { validator: _vm.bankValidator, message: '满分为10分，最低为0分' } ]},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1),_c('div',{staticClass:"btn"},[_c('van-button',{attrs:{"round":"","block":"","type":"primary","native-type":"submit"}},[_vm._v(" 提交 ")]),_c('van-button',{attrs:{"round":"","block":""}},[_vm._v(" 取消 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }